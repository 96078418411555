import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Page404Component } from './shared/components/page404/page404.component';
import { ButtonModule } from 'primeng/button';
import { ApiEndpointInterceptor } from './shared/http-interceptor/api-endpoint.interceptor';
import { Page401Component } from './shared/components/page401/page401.component';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService } from 'primeng';
import { FormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    Page401Component,
    Page404Component,
    AppComponent,
  ],
  imports: [
    HttpClientModule,
    ToastModule,
    ButtonModule,
    // SharedModule,
    NgProgressModule,
    NgProgressHttpModule,
    BrowserAnimationsModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    CheckboxModule,
  ],
  providers: [
    MessageService,
    DialogService,
    { provide: HTTP_INTERCEPTORS, useClass: ApiEndpointInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
