import { Injectable } from '@angular/core';
import { MessageService, Message } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class MsgService {

  constructor(
    private messageService: MessageService
  ) { }

  success(message: string) {
    this.messageService.add({ severity: 'success', detail: message });
  }

  info(message: string) {
    this.messageService.add({ severity: 'info', detail: message });
  }

  warn(message: string) {
    this.messageService.add({ severity: 'warn', detail: message });
  }

  error(message: string) {
    this.messageService.add({ severity: 'error', detail: message });
  }

  add(message: Message) {
    this.messageService.add(message);
  }

  addAll(message: Message[]) {
    this.messageService.addAll(message);
  }

  clear() {
    this.messageService.clear();
  }
}
