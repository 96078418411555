import { Constant } from './constant';
import { MsgService } from '../services/local/msg.service';
import { Message } from 'primeng/api';
import { ResponseModel } from '../models/models/response.model';

export class HandleError {
  constructor(protected messageService: MsgService) { }

  isValidResponse(x: ResponseModel): number {
    if (!x.e) {
      if (x.m) {
        this.messageService.warn(x.m);
      } else if (x.r) {
        let mess: Message[] = [];

        for (let key in x.r) {
          let element = x.r[key];
          mess.push({ severity: Constant.messageStatus.warn, detail: element });
        }

        this.messageService.addAll(mess);
      }
      else {
        this.messageService.error("Something wrong happened ! Please try again !");
      }
    }

    return x.e;
  }
}
