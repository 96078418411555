export const Constant = {
    messageStatus: {
        success: 'success',
        info: 'info',
        warn: 'warn',
        error: 'warn',
    },
    response: {
        e: 'e',
        m: 'm',
        r: 'r',
        c: 'c',
    },
    auths: {
        isLoginIn: 'loggedIn',
        token: 'token',
        userId: 'userId',
        userName: 'userName',
        currentUser: 'currentUser',
        fullName: 'fullName',
        tokenFirebase: 'tokenFirebase',
        datetimeLogin: 'datetimeLogin',
        expires: 'expires'
    },
    userType: {
        ADMIN: 1,
        USER1: 2,
        USER2: 3
    }
};
