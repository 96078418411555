import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from './base.service';
import { MsgService } from '../local/msg.service';
import { StorageService } from '../local/storage.service';
import { ResponseModel } from '../../models/models/response.model';
import { SelectItem } from 'primeng/api';
import { SelectModel } from '../../models/filter-models/select.model';

@Injectable()
export class GeneralService extends BaseService {
  constructor(
    protected messageService: MsgService,
    protected httpClient: HttpClient,
    @Inject(String) protected urlName: string,
    @Inject(String) protected apiName: string
  ) {
    super(messageService, httpClient, urlName, apiName);
  }

  async getById(id: any, arrCols: string[] = []): Promise<any> {
    let params = new HttpParams();
    params = params.append('id', id);

    if (arrCols.length > 0) {
      params = params.append('cols', arrCols.join(','));
    }

    const res = await this.getCustomApi('Get', params);
    if (!this.isValidResponse(res)) { return; }
    return res.r;
  }


  async getAll(arrCols: string[] = [], pageSize: number = 0, pageIndex: number = 0): Promise<any[]> {
    let params = new HttpParams();

    if (arrCols.length > 0) {
      params = params.append('cols', arrCols.join(','));
    }

    if (pageSize) {
      params = params.append('pageSize', pageSize + '');
    }

    if (pageIndex) {
      params = params.append('pageIndex', pageIndex + '');
    }

    const res = await this.getCustomApi('GetAll', params);
    if (!this.isValidResponse(res)) { return; }
    return res.r;
  }

  async getAllAsync(pageSize: number = 0, pageIndex: number = 0): Promise<any> {
    let params = new HttpParams();
    if (pageSize) {
      params = params.append('pageSize', pageSize + '');
    }
    if (pageIndex) {
      params = params.append('pageIndex', pageIndex + '');
    }
    const res = await this.getCustomApi('GetAll', params);
    if (!this.isValidResponse(res)) { return; }
    return res;
  }

  async create(model: object): Promise<ResponseModel> {
    // model['code'] =  model['code'].trim();
    const res = await this.postCustomApi('Create', model);
    if (!this.isValidResponse(res)) { return; }
    return res;
  }

  async update(model: object): Promise<ResponseModel> {
    // model['code'] =  model['code'].trim();
    const res = await this.postCustomApi('Update', model);
    if (!this.isValidResponse(res)) { return; }
    return res;
  }

  async delete(model: object): Promise<ResponseModel> {
    const res = await this.postCustomApi('Delete', model);
    if (!this.isValidResponse(res)) { return; }
    return res;
  }

  async getAllSelectModel(labelFull: boolean = true, hasValueAll: boolean = true): Promise<SelectModel[]> {
    const datas = await this.getAll();
    const selectModel = [];

    if (hasValueAll) {
      selectModel.push({ label: '-- Chọn dữ liệu --', data: null, value: null });
    }

    datas.forEach(element => {
      selectModel.push({
        label: labelFull ? `${element.name}` : element.name,
        data: element,
        value: element.id
      });
    });
    return selectModel;
  }
}
