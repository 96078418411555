import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MsgService } from '../local/msg.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StorageService } from '../local/storage.service';
import { Router } from '@angular/router';
import { ChangePasswordModel } from '../../models/models/changePassword.model';
import * as jwt_decode from 'jwt-decode';
import { ResponseModel } from '../../models/models/response.model';
import { LoginModel } from '../../models/models/login.model';
import { User } from '../../models/models/user.model';
import { Constant } from '../../infrastructure/constant';
import { GeneralService } from './general.service';
import { FilterBaseModel } from '../../models/filter-models/filterBase.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends GeneralService {

  constructor(
    protected messageService: MsgService,
    protected httpClient: HttpClient,
    private storageService: StorageService,
    private router: Router
  ) {
    super(messageService, httpClient, environment.apiUrl, 'UsersAdmin');
  }

  async login(model: LoginModel): Promise<ResponseModel> {
    const res = await this.postCustomApi('AuthenticateAdmin', model);
    if (res.e === 0) {
      //this.storageService.set(Constant.auths.expires, res.r.expires);
      this.storageService.set(Constant.auths.isLoginIn, 'true');
      this.storageService.set(Constant.auths.token, res.r.AccessToken);
      this.storageService.set(Constant.auths.userId, res.r.Id);
      this.storageService.set(Constant.auths.userName, res.r.UserName);
      this.storageService.set(Constant.auths.fullName, res.r.FirstName + " " + res.r.LastName);
    }
    return res;
  }

  async logout() {
    this.storageService.removeAll();
    this.router.navigate(['/login']);
  }

  isLogged() {
    const token = this.storageService.get(Constant.auths.token);
    if (token) {
      const jwt = jwt_decode(token);
      if (jwt.exp * 1000 > new Date().valueOf()) {
        return true;
      }
    }
    return false;
  }

  async changePassWord(model: ChangePasswordModel) {
    //model.userId = this.getUserId() as any;
    const res = await this.postCustomApi('ChangePassWord', model);
    if (!this.isValidResponse(res)) { return; }

    return res;
  }

  async getCurrentUserInfo(): Promise<User> {
    const res = await this.getCustomApi('GetCurrentUserInfo', null);
    if (!this.isValidResponse(res)) { return; }

    return res.r;
  }

  getUserId(): string {
    return this.storageService.get(Constant.auths.userId);
  }

  getUserName(): string {
    return this.storageService.get(Constant.auths.userName);
  }

  getFullName(): string {
    return this.storageService.get(Constant.auths.fullName);
  }

  async getListUsersAdmins(filterBaseModel: FilterBaseModel) {
    if (filterBaseModel.searchText){
      filterBaseModel.searchText = filterBaseModel.searchText.trim();
    }
    const res = await this.postCustomApi('GetUsersAdmins', filterBaseModel);
    return res.r as any[];
  }

  async getListUsers(filterBaseModel: FilterBaseModel) {
    if (filterBaseModel.searchText){
      filterBaseModel.searchText = filterBaseModel.searchText.trim();
    }
    const res = await this.postCustomApi('GetUsers', filterBaseModel);
    return res.r as any[];
  }

  async getListUsersAdminsAsync(filterBaseModel: FilterBaseModel) {
    const data = await this.getListUsersAdmins(filterBaseModel);
    // const results = [];
    // data['list'].forEach(fe => {
    //   results.push(fe);
    // });
    return data;
  }

  async getListUsersAsync(filterBaseModel: FilterBaseModel) {
    const data = await this.getListUsers(filterBaseModel);
    // const results = [];
    // data['list'].forEach(fe => {
    //   results.push(fe);
    // });
    return data;
  }
}
