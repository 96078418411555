// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  companyName: 'WEB VIDEO',

  // apiUrl: 'http://localhost:4000',
  // urlImage: 'http://localhost:4000',
  apiUrl: 'https://streamapi.aliens.vn',
  urlImage: 'https://streamapi.aliens.vn',
  uploadImage: {
    maxFileSize: 1000000,
    defaultImage: 'assets/layout/images/no-image.png'
  },
  uploadIcon: {
    maxFileSize: 1000000,
    defaultImage: 'assets/layout/images/no-image.png'
  },
};
