import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';
import { Constant } from './shared/infrastructure/constant';
import { Page404Component } from './shared/components/page404/page404.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { AuthLoginGuard } from './shared/guard/authLogin.guard';
import { Page401Component } from './shared/components/page401/page401.component';


const routes: Routes = [
  {
    path: '', loadChildren: () => import('./app-secured/app-secured.module').then(m => m.AppSecuredModule),
    canActivate: [AuthGuard], canActivateChild: [AuthGuard]
  },
  {
    path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    canActivate: [AuthLoginGuard]
  },
  { path: '401', component: Page401Component },
  { path: '404', component: Page404Component },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', preloadingStrategy: NoPreloading })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
